/* global wp, jQuery */
/**
 * File customizer.js.
 *
 * Theme Customizer enhancements for a better user experience.
 *
 * Contains handlers to make Theme Customizer preview reload changes asynchronously.
 */

( function(jQuery ) {
    document.addEventListener(
        "DOMContentLoaded", () => {
            new Mmenu( "#menu", {
               "offCanvas": {
                  "position": "right"
               },
               "navbars": [
                {
                    "content": [
                        '<a href="'+custom.site_url+'"><img src="'+custom.logo_url+'" alt="'+custom.logo_name+'" width="238" height="40" /></a>',
                    ]
                }
                ],
               "theme": "dark"
            });
        }
    );
}( jQuery ) );
jQuery(document).ready(function(jQuery){
    jQuery('.content-slider').owlCarousel({
        loop:false,
        nav:true,
        items:1,
        singleItem: true,
        autoplay:true,
        autoplayTimeout:8000,
    })

    /* Sticky header for add and rmeove class */
    jQuery(window).scroll(function() {
        var sticky = jQuery('header'),
            scroll = jQuery(window).scrollTop();
        if (scroll >= 200) {
            sticky.addClass('fixed-header');
        }
        else {
            if(sticky.hasClass("fixed-header")){
                sticky.removeClass('fixed-header');
            }
        }
    });


    // /* For PopUp  Team Page */
    jQuery('.team-section .team-col .close-modal').on('click', function(e) {
        jQuery(this).parent().parent().find("button").trigger("click");
        jQuery(this).parent().parent(".team-popup").removeClass("open");
    });

    /**smooth scroll */
    jQuery(".scroll-to-btm").click(function() {
        jQuery('html,body').animate({
            scrollTop: jQuery(".our-content-section").offset().top},
            'slow');
    });

    jQuery('.count-number').each(function() {
        var $this =  jQuery(this),
            countTo = $this.attr('data-count');

        jQuery({ countNum: $this.text()}).animate({
            countNum: countTo
        },

        {

        duration: 3000,
        easing:'linear',
        step: function() {
            $this.text(Math.floor(this.countNum));
        },
        complete: function() {
            $this.text(this.countNum);
            //alert('finished');
        }

        });

});
      // count Js
    var a = 0;
    jQuery(window).scroll(function () {
        var oTop = jQuery('.counter-section').offset().top - window.innerHeight;
        if (a == 0 && jQuery(window).scrollTop() > oTop) {
            a = 1;
            countNumber();
        }
    });

function countNumber() {
    jQuery('.count-number').html();
    $ = jQuery;
    jQuery(".counter-section .count-number").each(function () {
        var $this = $(this),
            countTo = $this.attr("data-countto");
        countDuration = parseInt($this.attr("data-duration"));
        $({
            counter: $this.text()
        }).animate({
            counter: countTo
        }, {
            duration: countDuration,
            easing: "linear",
            step: function () {
                $this.text(Math.floor(this.counter));
            },
            complete: function () {
                $this.text(this.counter);
            }
        });
    });
    jQuery(".counter-row .count-number").each(function () {
        var $this = $(this),
            countTo = parseInt($this.attr("data-countto"));
        countDuration = parseInt($this.attr("data-duration"));
        $({
            counter: $this.text()
        }).animate({
            counter: countTo
        }, {
            duration: countDuration,
            easing: "linear",
            step: function () {
                $this.text(Math.floor(this.counter));
            },
            complete: function () {
                $this.text(this.counter);
            }
        });
    });
}
});
